<template>
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-fullscreen">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="AccountModalLabel">Example</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">

                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Save changes</button>
                  </div>
                </div>
              </div>
            </div>

</template>
<script>
export default {
  name: 'ExampleModal',
  methods: {

        setCompId: function(selected) {
            console.log(selected);
            this.selectedComputer = selected - 1;
        },

    },
  data () {
    return {

    }    
  }
}
</script>
<style lang="scss" scoped>

</style>