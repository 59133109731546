<template>
<a
    v-on:click="selectHardware()"
    data-bs-toggle="modal"
    data-bs-target="#singleHardwareModal"
  >
    <div class="card" >
      <img src="https://azcd.harveynorman.com.au/media/catalog/product/cache/21/image/992x558/9df78eab33525d08d6e5fb8d27136e95/t/p/tp401ma-bz253ts-asus-vivobook-flip-14-inch-2-in-1-device.jpg" class="card-img-top" alt="...">
      <div class="card-body">
        <h5 class="card-title">{{ hardware.hardwareSpecs.Brand }}</h5>
      </div>
    </div>
</a>
</template>
<script>
import { eventBus } from "../main";
export default {
  props: ["hardware"],
  methods: {
    selectHardware: function () {
      this.selectedComputer = this.hardware.id - 1;
      console.log(this.selectedComputer);
      eventBus.$emit("hardwareSelectionWasUpdated", this.selectedComputer);
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  border: none;
}
</style>