<template>
<!-- Account Modal -->
            <div class="modal fade" id="AccountModal" tabindex="-1" aria-labelledby="AccountModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-fullscreen">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="AccountModalLabel">Profile</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <p v-if="$auth.isAuthenticated" class="">{{ $auth.user.name }}!</p>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Save changes</button>
                  </div>
                </div>
              </div>
            </div>

</template>
<script>
export default {
  name: 'AccountModal',
  methods: {

        setCompId: function(selected) {
            console.log(selected);
            this.selectedComputer = selected - 1;
        },

        clearquicksearch: function() {
            this.quickSearchCompStyle= 'LAPTOP',
            this.quickSearchProcessor= '',
            this.quickSearchRAM= 0,
            this.quickSearchStorage= 0,
            this.counterStorage= 32,
            this.counterRam= 2
        },

        getpricelaptop: function () {
        },
        showsinglelaptop: function () {
    
        },

        increaseStorage: function (step) {
            this.counterStorage *= step;
        },

        decreaseStorage: function (step) {
            this.counterStorage /= step;
        },
        increaseRam: function (step) {
            this.counterRam *= step;
        },

        decreaseRam: function (step) {
            this.counterRam /= step;
        }
    },
  data () {
    return {
     quickSearchCompStyle: 'LAPTOP',
        quickSearchProcessor: '',
        quickSearchRAM: 0,
        quickSearchStorage: 0,
        counterStorage: 32,
        counterRam: 2,
    }    
  }
}
</script>
<style lang="scss" scoped>

</style>