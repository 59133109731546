<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="BookmarksModal"
    tabindex="-1"
    aria-labelledby="BookmarksModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="BookmarksModalLabel">Bookmarks</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <h6>Saved Laptops</h6>
            </div>
            <div v-for="savedHardware in savedHardwares" :savedHardware="savedHardware" :key="savedHardware.id" class="">
              <BookmarkHardwareCard :savedHardware="savedHardware" />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <h6>Saved Quick Searches</h6>
            </div>
            <div v-for="savedsearch in savedsearches" :savedsearch="savedsearch" :key="savedsearch.id" class="">
              <BookmarkSearchCard :savedsearch="savedsearch" />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BookmarkSearchCard from "./modalpartials/BookmarkSearchCard.vue";
import BookmarkHardwareCard from "./modalpartials/BookmarkHardwareCard.vue";
import { eventBus } from '../../main';
export default {
  name: "BookmarksModal",
  components: {
    BookmarkSearchCard,
    BookmarkHardwareCard
  },
  created() {
    eventBus.$on('favourSearchUpdated', (favourite) => {
      //this.savedsearches = favourite;
      console.log(favourite);
      this.savedsearches.push(favourite);
      console.log(this.savedsearches);
    
    },)
    eventBus.$on('favouriteHardwareUpdated', (favourite) => {
      //this.savedsearches = favourite;
      console.log(favourite);
      this.savedHardwares.push(favourite);
      console.log(this.savedHardwares);
    })
  },
  data () {
    return {
      savedsearches: [

      ],
      savedHardwares: [

      ]
    }    
  }
};
</script>
<style lang="scss" scoped>
</style>