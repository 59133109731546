<template>
  <div class="row">
    <div class="col-12 pb-2">
      <h3>Recommended Feed</h3>
    </div>
    <div
      v-for="hardware in hardwares.slice(0, 4)"
      :hardware="hardware"
      :key="hardware.id"
      class="col-6"
    >
      <RecomendedHardwareCard :hardware="hardware" />
    </div>
  </div>
</template>
<script>
//import { eventBus } from '../main';
import RecomendedHardwareCard from "@/components/RecomendedHardwareCard.vue";
import hardwareData from "../data/hardwaredata.json";
export default {
  name: "RecommendedList",
  components: {
    RecomendedHardwareCard,
  },
  methods: {},
  data() {
    return {
      hardware: {},
      hardwares: hardwareData,
    };
  },
};
</script>
<style lang="scss" scoped>
.events {
  margin-top: 100px;
  text-align: center;
}
</style>