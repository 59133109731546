<template>
<!-- Modal -->
            <div class="modal fade" id="singleHardwareModal" tabindex="-1" aria-labelledby="singleHardwareModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-fullscreen ">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">{{ this.selectedComputer }} </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                      <div id="singleComputer" class="container">
                            <div class="row ">
                                <div class="col-12 pb-4">
                                    <h1>{{ computers[selectedComputer].hardwareSpecs.Brand}}</h1>
                                </div>
                                <div class="col-12 pb-4">
                                    <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                                        <div class="carousel-indicators">
                                            <button type="button" data-bs-target="#carouselExampleIndicators"
                                                data-bs-slide-to="0" class="active" aria-current="true"
                                                aria-label="Slide 1"></button>
                                            <button type="button" data-bs-target="#carouselExampleIndicators"
                                                data-bs-slide-to="1" aria-label="Slide 2"></button>
                                            <button type="button" data-bs-target="#carouselExampleIndicators"
                                                data-bs-slide-to="2" aria-label="Slide 3"></button>
                                        </div>
                                        <div class="carousel-inner">
                                            <div class="carousel-item active">
                                                <img v-bind:src="computers[selectedComputer].imgs[0]"
                                                    class="d-block w-100" alt="...">
                                            </div>
                                            <div class="carousel-item">
                                                <img v-bind:src="computers[selectedComputer].imgs[1]"
                                                    class="d-block w-100" alt="...">
                                            </div>
                                            <div class="carousel-item">
                                                <img v-bind:src="computers[selectedComputer].imgs[2]"
                                                    class="d-block w-100" alt="...">
                                            </div>
                                        </div>
                                        <button class="carousel-control-prev" type="button"
                                            data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span class="visually-hidden">Previous</span>
                                        </button>
                                        <button class="carousel-control-next" type="button"
                                            data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span class="visually-hidden">Next</span>
                                        </button>
                                    </div>
                                </div>
                                                                <div class="col-12 pb-1 text-center">
                                    <p>
                                        <a class="btn btn-primary w-100" data-bs-toggle="collapse"
                                            href="#DescriptionCollapse" role="button" aria-expanded="true"
                                            aria-controls="DescriptionCollapse">
                                            Description
                                        </a>
                                    </p>
                                    <div class="" id="DescriptionCollapse">
                                        <div class="card card-body">
                                            {{ computers[selectedComputer].description }}
                                        </div>
                                    </div>

                                </div>
                                <div class="col-12 pb-1 text-center">
                                    <p>
                                        <a class="btn btn-primary w-100" data-bs-toggle="collapse"
                                            href="#SpecificationsCollapse" role="button" aria-expanded="false"
                                            aria-controls="SpecificationsCollapse">
                                            Specifications
                                        </a>
                                    </p>
                                    <div class="collapse" id="SpecificationsCollapse">
                                        <p>{{ computers[selectedComputer].hardwareSpecs.Brand}}</p>
                                        <ul>
                                            <li  v-for="hardwareSpec in computers[selectedComputer].hardwareSpecs" :key="hardwareSpec">{{ hardwareSpec}}</li>
                                        </ul>
                                        <table class="table">
                                            <tbody>
                                                <tr>
                                                    <td>Processor</td>
                                                    <td>{{computers[selectedComputer].processorType}}</td>
                                                </tr>
                                                <tr>
                                                    <td>RAM</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Storage</td>
                                                    <td>{{computers[selectedComputer].totalStorage }}</td>
                                                </tr>
                                                <tr>
                                                    <td>Operating System</td>
                                                    <td>{{computers[selectedComputer].operatingSystem}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Touch Screen</td>
                                                    <td>{{computers[selectedComputer].touchscreen }} </td>
                                                </tr>
                                                <tr>
                                                    <td>Display Size</td>
                                                    <td>{{computers[selectedComputer].displaySize }} {{computers[selectedComputer].displaySizeUOM }} </td>
                                                </tr>
                                                <tr>
                                                    <td>Display Resolution</td>
                                                    <td>{{computers[selectedComputer].displayResolutionPixels }} {{computers[selectedComputer].displayResolutionCat }}</td>
                                                </tr>
                                                <tr>
                                                    <td>Graphics</td>
                                                    <td>{{computers[selectedComputer].graphicsProcessor }}</td>
                                                </tr>
                                                <tr>
                                                    <td>HDMI Ports</td>
                                                    <td>{{computers[selectedComputer].hdmiPorts }}</td>
                                                </tr>
                                                <tr>
                                                    <td>HDMI Ports</td>
                                                    <td>{{computers[selectedComputer].hdmiPorts }}</td>
                                                </tr>
                                                <tr>
                                                    <td>USB 2.0</td>
                                                    <td>{{computers[selectedComputer].usb2Ports }}</td>
                                                </tr>
                                                <tr>
                                                    <td>USB 3.0</td>
                                                    <td>{{computers[selectedComputer].usb31Ports }}</td>
                                                </tr>
                                                <tr>
                                                    <td>USB C</td>
                                                    <td>{{computers[selectedComputer].usbCPorts }}</td>
                                                </tr>
                                                <tr>
                                                    <td>Storage</td>
                                                    <td>{{computers[selectedComputer].totalStorage }}</td>
                                                </tr>
                                                <tr>
                                                    <td>Card Reader</td>
                                                    <td>{{computers[selectedComputer].cardReader }}</td>
                                                </tr>
                                                <tr>
                                                    <td>Webcam</td>
                                                    <td>{{computers[selectedComputer].webcam }}</td>
                                                </tr>
                                                <tr>
                                                    <td>Bluetooth</td>
                                                    <td>{{computers[selectedComputer].bluetooth }}</td>
                                                </tr>
                                                <tr>
                                                    <td>WiFi</td>
                                                    <td>{{computers[selectedComputer].WiFi }}</td>
                                                </tr>
                                                <tr>
                                                    <td>Webcam</td>
                                                    <td>{{computers[selectedComputer].webcam }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>

                                <div class="col-12 pb-1 text-center">
                                    <p>
                                        <a class="btn btn-primary w-100" data-bs-toggle="collapse"
                                            href="#AvailibleCollapse" role="button" aria-expanded="false"
                                            aria-controls="AvailibleCollapse">
                                            Availible At
                                        </a>
                                    </p>
                                    <div class="collapse" id="AvailibleCollapse">
                                        <div class="card card-body">
                                            Harvey Norman
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                  </div>
                  <div class="modal-footer">
                    <button v-on:click="favouriteHardware()" type="button" class="btn btn-primary">Bookmark</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Save changes</button>
                  </div>
                </div>
              </div>
            </div>

</template>
<script>
import { eventBus } from '../../main';
import hardwareData from "../../data/hardwaredata.json";
export default {
  name: 'hardwareSingleModal',
  props: [
      'hardwares'
  ],
  methods: {
    favouriteHardware(){
        this.savedHardware = {
            savedHardwareId: this.selectedComputer,
            savedHardwareName: this.computers[this.selectedComputer].brand + " " + this.computers[this.selectedComputer].model,
        };
        eventBus.$emit('favouriteHardwareUpdated', this.savedHardware);
    }
    },
    created() {
        eventBus.$on('hardwareSelectionWasUpdated', (hardware) => {
            this.selectedComputer = hardware; 
        });
    },
  data () {
    return {
    selectedComputer: 0,
    computers: hardwareData

    }    
  }
}
</script>
<style lang="scss" scoped>

</style>