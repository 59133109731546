<template>
  <div
    class="modal fade"
    id="QuickSearchModal"
    tabindex="-1"
    aria-labelledby="QuickSearchModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="QuickSearchModalLabel">Quick Search</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div id="quickSearch" class="container">
            <div class="row justify-content-end">
              <div class="col-2 pb-4 text-align-right">
                <a
                  v-on:click="clearquicksearch()"
                  class="btn btn-primary btn-sm"
                  href="#"
                  role="button"
                  ><i class="fas fa-undo"></i
                ></a>
              </div>
              <div class="col-2 text-right">
                <a
                  v-on:click="savequicksearch()"
                  class="btn btn-primary btn-sm"
                  role="button"
                  ><i class="far fa-save"></i
                ></a>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label for="" class="form-label"
                  ><i class="fas fa-microchip p-2"></i
                  >{{ hardware }} Style</label
                >
              </div>
              <div class="col-12 pb-4">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="qsf-Desktop"
                    value="Desktop"
                    v-model="quickSearchCompStyle"
                  />
                  <label class="form-check-label" for="qsf-Desktop"
                    >Desktop</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="qsf-laptop"
                    value="Laptop"
                    v-model="quickSearchCompStyle"
                  />
                  <label class="form-check-label" for="qsf-laptop"
                    >Laptop</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="qsf-2in1"
                    value="2 in 1"
                    v-model="quickSearchCompStyle"
                  />
                  <label class="form-check-label" for="inlineRadio2"
                    >2 in 1</label
                  >
                </div>
              </div>
              <div class="col-12 pb-4">
                <label for="exampleDataList" class="form-label"
                  ><i class="fas fa-microchip p-2"></i>Processor</label
                >
                <input
                  class="form-control"
                  list="datalistOptions"
                  id="exampleDataList"
                  placeholder="Type to search..."
                  v-model="quickSearchProcessor"
                />
                <datalist id="datalistOptions">
                  <option value="INTEL - I3"></option>
                  <option value="INTEL - I5"></option>
                  <option value="INTEL - I7"></option>
                  <option value="INTEL - I9"></option>
                  <option value="AMD - A4"></option>
                </datalist>
              </div>
              <div class="col-12 pb-4">
                <p><i class="fas fa-memory p-2"></i>Ram: {{ counterRam }}</p>
                <div
                  class="btn-group w-100"
                  role="group"
                  aria-label="Basic outlined example"
                >
                  <button
                    v-on:click="decreaseRam(2)"
                    v-if="counterRam !== 2"
                    class="btn btn-outline-primary"
                    href="#"
                    role="button"
                  >
                    <i class="fas fa-minus"></i>
                  </button>
                  <button
                    v-on:click="increaseRam(2)"
                    v-if="counterRam !== 64"
                    class="btn btn-outline-primary w-50"
                    href="#"
                    role="button"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
              </div>
              <div class="col-12 pb-4">
                <p>
                  <i class="fas fa-hdd p-2"></i>Storage: {{ counterStorage }}
                </p>
                <div
                  class="btn-group w-100"
                  role="group"
                  aria-label="Basic outlined example"
                >
                  <button
                    v-on:click="decreaseStorage(2)"
                    v-if="counterStorage !== 32"
                    class="btn btn-outline-primary"
                    href="#"
                    role="button"
                  >
                    <i class="fas fa-minus"></i>
                  </button>
                  <button
                    v-on:click="increaseStorage(2)"
                    v-if="counterStorage !== 2048"
                    class="btn btn-outline-primary w-50"
                    href="#"
                    role="button"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 pb-2">
                <a
                  class="btn btn-outline-primary"
                  data-bs-toggle="collapse"
                  href="#advancedOptionsSearch"
                  role="button"
                  aria-expanded="false"
                  aria-controls="advancedOptionsSearch"
                >
                  Advanced Search
                </a>
              </div>
              <div class="col-12 pb-4">
                <div class="collapse" id="advancedOptionsSearch">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value="1"
                      id="touchscreen"
                      v-model="quickSearchTouch"
                    />
                    <label class="form-check-label" for="touchscreen">
                      Touch Screen
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value="1"
                      id="DedicatedGraphics"
                      v-model="quickSearchDedicatedGraphics"
                    />
                    <label class="form-check-label" for="DedicatedGraphics">
                      Dedicated Graphics
                    </label>
                  </div>
                  <div class="form-check mb-2">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value="1"
                      id="digitalstylus"
                      v-model="quickSearchDigitalStylus"
                    />
                    <label class="form-check-label" for="digitalstylus">
                      Digital Stylus
                    </label>
                  </div>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Min Price"
                      aria-label="minprice"
                      v-model="quickSearchMinPrice"
                    />
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Max Price"
                      aria-label="maxprice"
                      v-model="quickSearchMaxPrice"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>

          <router-link :to="'/search'">
        Search
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { eventBus } from "../../main";
export default {
  name: "QuickSearchModal",
  props: ["update-search-bookmark"],
  created() {},
  methods: {
    setCompId: function (selected) {
      console.log(selected);
      this.selectedComputer = selected - 1;
    },

    clearquicksearch: function () {
      (this.quickSearchCompStyle = "LAPTOP"),
        (this.quickSearchProcessor = ""),
        (this.quickSearchRAM = 0),
        (this.quickSearchStorage = 0),
        (this.counterStorage = 32),
        (this.counterRam = 2);
    },

    savequicksearch: function () {
      this.quickSearchRAM = this.counterRam;
      this.quickSearchStorage = this.counterStorage;
      //console.log(eventBus.savedsearches.length);
      this.savedSearch = {
        style: this.quickSearchCompStyle,
        processor: this.quickSearchProcessor,
        ram: this.quickSearchRAM,
        storage: this.quickSearchStorage,
        touch: this.quickSearchTouch,
        dedicatedGraphics: this.quickSearchDedicatedGraphics,
        DigitalStylus: this.quickSearchDigitalStylus,
        MinPrice: this.quickSearchMinPrice,
        MaxPrice: this.quickSearchMaxPrice,
      };
      eventBus.$emit("favourSearchUpdated", this.savedSearch);
      /*
      this.quickSearchRAM = this.counterRam;
      this.quickSearchStorage = this.counterStorage;

      this.savedquicksearch = 
        this.quickSearchCompStyle +
        " " +
        this.quickSearchProcessor +
        " " +
        this.quickSearchRAM +
        " " +
        this.quickSearchStorage +
        " " +
        this.quickSearchTouch +
        " " +
        this.quickSearchDedicatedGraphics +
        " " +
         this.quickSearchDigitalStylus +
        " " +
        this.quickSearchMinPrice +
        " " +
        this.quickSearchMaxPrice +
        " "
        
       console.log(this.$root.randomWord);
      this.$emit('update-search-bookmark', counterRam)
      console.log(counterRam);
      console.log(this.counterRam);
       */
    },

    getpricelaptop: function () {},
    showsinglelaptop: function () {},

    increaseStorage: function (step) {
      this.counterStorage *= step;
    },

    decreaseStorage: function (step) {
      this.counterStorage /= step;
    },
    increaseRam: function (step) {
      this.counterRam *= step;
    },

    decreaseRam: function (step) {
      this.counterRam /= step;
    },
  },
  data() {
    return {
      savedsearch: [],
      hardware: "Computer",
      counterStorage: 32,
      counterRam: 2,
      quickSearchCompStyle: "",
      quickSearchProcessor: "",
      quickSearchRAM: 0,
      quickSearchStorage: 0,
      quickSearchTouch: 0,
      quickSearchDedicatedGraphics: 0,
      quickSearchDigitalStylus: 0,
      quickSearchMinPrice: 0,
      quickSearchMaxPrice: 100000,
    };
  },
};
</script>
<style lang="scss" scoped>
</style>