<template>
<nav class="navbar fixed-bottom navbar-light bg-light">
            <div class="container-fluid">
                <ul class="nav justify-content-end">
                    <li class="nav-item ">
                      <font-awesome-icon icon="Search" />
                        <a class="nav-link" aria-current="page" data-bs-toggle="modal" data-bs-target="#QuickSearchModal"><i class="fas fa-search"></i></a>
                    </li>
                    <li class="nav-item" >
                        <a class="nav-link" href="#" data-bs-toggle="modal" data-bs-target="#BookmarksModal"><i
                                class="far fa-heart"></i></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#" data-bs-toggle="modal" data-bs-target="#CompareModal"><i
                                class="fas fa-table"></i></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#" data-bs-toggle="modal" data-bs-target="#AccountModal"><i
                                class="far fa-user-circle"></i></a>
                    </li>
                    <li>
                      <button type="button" class="btn btn-outline-primary" v-if="!$auth.isAuthenticated" @click="login" >Sign Up</button>
                      </li>
                </ul>
            </div>
        </nav>
</template>
<script>
export default {
  name: 'Nav',
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    }
  }
}
</script>
<style lang="scss" scoped>

</style>