<template>
  <div class="col-12 pb-4">
    <div class="card">
      <div class="container card-header">
        <div class="row">
          <div class="col-3">{{ savedsearch.style }} </div>
          <div class="col-3">{{ savedsearch.processor }}</div>
          <div class="col-3">{{ savedsearch.ram }} GB</div>
          <div class="col-3">{{ savedsearch.storage }} GB</div>
        </div>
      </div>

      <div class="card-body">
        <p class="card-text" v-if="savedsearch.touch">Touch: Yes</p>
        <p class="card-text" v-if="savedsearch.dedicatedGraphics">Dedicated Graphics: Yes</p>
        <p class="card-text" v-if="savedsearch.DigitalStylus">Capacitive Stylus: Yes</p>
        <p class="card-text">
          Price: ${{ savedsearch.MinPrice }} - ${{ savedsearch.MaxPrice }}
        </p>
        <a href="#" class="btn btn-primary">GO</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name:"BookmarkSearchCard",
  props: ["savedsearch"],
};
</script>
<style lang="scss" scoped>
</style>