<template>
  <div class="col-12 pb-4">
    <div class="card">
      <div class="container card-header">
        <div class="row">
            
        </div>
      </div>

      <div class="card-body">
        <p class="card-text" >Touch: Yes</p>
        <p class="card-text">
          Price: ${{ savedhardware.savedHardwareName}}
        </p>
        <a href="#" class="btn btn-primary">GO</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name:"BookmarkHardwareCard",
  props: ["savedhardware"],
};
</script>
<style lang="scss" scoped>
</style>