import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'
import { authGuard } from "../auth/authGuard";

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/search',
      name: 'search',
      component: () => import('../views/Search.vue')
    },
    {
      path: '/test',
      name: 'test',
      component: () => import('../views/testroute.vue')
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('../views/About.vue')
    },
    {
      path: '/event/:id',
      name: 'eventSingle',
      component: () => import('../views/EventSingle.vue'),
      beforeEnter: authGuard
    },
    {
      path: '/computer/:id',
      name: 'HardwareSingle',
      component: () => import('../views/HardwareSingle.vue'),
      beforeEnter: authGuard
    },
    {
      path: '/:id',
      name: 'HardwareSingleModal',
      beforeEnter: authGuard
    }

  ]
})