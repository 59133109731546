<template>
  <div id="app" class="container-fluid disable-dbl-tap-zoom">
    <Nav v-if="$auth.isAuthenticated" />
    <router-view />
    <QuickSearchModal v-if="$auth.isAuthenticated" />
    <ExampleModal v-if="$auth.isAuthenticated" />
    <AccountModal v-if="$auth.isAuthenticated" />
    <BookmarksModal v-if="$auth.isAuthenticated" @update-search-bookmark="updateCart"/>
    <CompareModal v-if="$auth.isAuthenticated" />
    <HardwareSingleModal v-if="$auth.isAuthenticated" />
    <HardwareList v-if="$auth.isAuthenticated" />
  </div>
</template>
<script>
import Nav from "./components/partials/Nav.vue";

import AccountModal from "./components/modals/AccountModal";
import BookmarksModal from "./components/modals/BookmarksModal";
import CompareModal from "./components/modals/CompareModal";
import QuickSearchModal from "./components/modals/QuickSearchModal";
import ExampleModal from "./components/modals/ExampleModal";
import HardwareSingleModal from "./components/modals/HardwareSingleModal";
export default {
  name: "app",
  components: {
    Nav,
    ExampleModal,
    AccountModal,
    BookmarksModal,
    CompareModal,
    QuickSearchModal,
    HardwareSingleModal
  },
  data() {
    return {
      savedHardware: []
    }
  }
};

</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;900&display=swap');
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid"; // fas
@import "~@fortawesome/fontawesome-free/scss/regular"; // far
@import "~@fortawesome/fontawesome-free/scss/brands"; // fab
#app {
  height: 100vh;
  padding: 0px;
}

h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900 !important;
}

p {
  font-family: 'Montserrat', sans-serif;
}
#main-frame {
  height: 90vh;
}

#quickSearch {
  transition: all 2s linear;
}

.disable-dbl-tap-zoom {
  touch-action: manipulation;
}

.gradient-bg {
  background: #121fcf;
  background: -webkit-linear-gradient(
    to bottom right,
    #121fcf 4%,
    #0ebccf 100%
  );
  background: -moz-linear-gradient(to bottom right, #121fcf 4%, #0ebccf 100%);
  background: linear-gradient(to bottom right, #121fcf 4%, #0ebccf 100%);

  /*
            background: rgb(34,195,134);
background: linear-gradient(27deg, rgba(34,195,134,0.8167053364269141) 0%, rgba(45,193,253,0.7842227378190255) 100%); 
         */
}

.card {
  border: none;
}

.card-text {
  font-size: 0.75rem;
}

.card-body {
  padding: 0rem 1rem;
}

.displaynone {
  display: none;
}
.btn-rnd {
  border-radius: 1.25rem;
}

/*
        .dNone {
            display: none;
        }
        .dBlock {
            display: block;
            animation: fadeIn ease 20s;
        }
        */
@keyframes dBlock {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.h-100 {
  height: 100vh;
}
</style>