<template>
  <div class="home">
    <!-- Before authentication -->
    <div
      v-if="!$auth.isAuthenticated"
      id="loginscreen"
      class="container-fluid gradient-bg"
    >
      <div class="container" style="height: 100vh">
        <div class="row align-items-center" style="height: 100vh">
          <div class="col text-center">
            <i class="fa fa-laptop text-light pb-4" aria-hidden="true" style="font-size: 64px;"></i>
            <button type="button" class="btn btn-outline-light w-100" @click="login">
              Sign in to continue
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Header After Authenticated -->
    <div
      v-if="$auth.isAuthenticated"
      id="header"
      class="container-fluid gradient-bg"
    >
      <div class="container">
        <div class="row" style="padding: 12vh 0vh">
          <div class="col-12 pb-1 text-center text-light">
            <h3>Welcome {{ $auth.user.nickname }}</h3>
            <h5 class="pb-4">Let's find your next Computer</h5>
          </div>
          <div class="col-lg-6">
            <button
              type="button"
              class="btn btn btn btn-outline-light w-100"
              style="border-radius: 1.25rem"
              data-bs-toggle="modal"
              data-bs-target="#QuickSearchModal"
            >
              Quick Search
            </button>
            <!--<button @click="attachRed = !attachRed" class="btn btn btn btn-outline-light w-100" style="border-radius:1.25rem;" href="#" role="button">Search</button></button>-->
          </div>
          <div class="col-lg-6 mt-2">
            <button
              class="btn btn btn-outline-light w-100"
              style="border-radius: 1.25rem"
              role="button"
              data-bs-toggle="modal"
              data-bs-target="#guidedSearchModal"
            >
              I need Help to find a computer
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /header -->

    <!-- Recommended Feed --> 
    <div
      v-if="$auth.isAuthenticated"
      id="recommended-feed"
      class="container mt-4 mb-4"
    >
    <RecommendedList />

    </div>

    <!-- Advert -->
    <div v-if="$auth.isAuthenticated" id="advert" class="container-fluid">
      <div class="row gradient-bg pt-4 pb-4 text-light">
        <div class="col-12 text-center">
          <h6 class="mb-0">Advertising space</h6>
        </div>
      </div>
    </div>
    <!-- CATEGORIES  -->
    <div v-if="$auth.isAuthenticated" id="computertypes" class="container">
      <div class="row pt-4 pb-4 text-center">
        <div class="col-12">
          <div
            class="btn-group btn-group-sm w-100"
            role="group"
            aria-label="Basic radio toggle button group"
          >
            <input
              v-on:click="brandisHidden = true"
              type="radio"
              class="btn-check"
              name="btnradio"
              id="btnradio1"
              autocomplete="off"
              selected
            />
            <label class="btn btn-outline-primary" for="btnradio1">Brand</label>

            <input
              v-on:click="brandisHidden = false"
              type="radio"
              class="btn-check"
              name="btnradio"
              id="btnradio2"
              autocomplete="off"
            />
            <label class="btn btn-outline-primary" for="btnradio2">Type</label>
          </div>
        </div>
        <div class="col-12">
          <div class="container">
            <div v-if="brandisHidden" id="computerBrand" class="row">
              <div class="col-6 col-lg-2">
                <router-link :to="'/test'">
                <img
                  src="img/imgs/icons/dell@2x.png"
                  class="img-fluid"
                  alt="Dell Logo"
                />
                </router-link>
              </div>
              <div class="col-6 col-lg-2">
                <img
                  src="img/imgs/icons/microsoft@2x.png"
                  class="img-fluid"
                  alt="Microsoft Logo"
                />
              </div>
              <div class="col-6 col-lg-2">
                <img
                  src="img/imgs/icons/acer@2x.png"
                  class="img-fluid"
                  alt="Acer Logo"
                />
              </div>
              <div class="col-6 col-lg-2">
                <img
                  src="img/imgs/icons/asus@2x.png"
                  class="img-fluid"
                  alt="Asus Logo"
                />
              </div>
              <div class="col-6 col-lg-2">
                <img
                  src="img/imgs/icons/hp@2x.png"
                  class="img-fluid"
                  alt="HP logo"
                />
              </div>
              <div class="col-6 col-lg-2">
                <img
                  src="img/imgs/icons/apple@2x.png"
                  class="img-fluid"
                  alt="apple logo"
                />
              </div>
              <div class="col-6 col-lg-2">
                <img
                  src="img/imgs/icons/lenovo@2x.png"
                  class="img-fluid"
                  alt="lenovo logo"
                />
              </div>
            </div>
            <div v-if="!brandisHidden" id="computerType" class="row" style="">
              <div class="col-6">
                <img
                  src="img/imgs/icons/laptop.svg"
                  class="img-fluid"
                  alt="Dell Logo"
                />
              </div>
              <div class="col-6">
                <img
                  src="img/imgs/icons/tablet.svg"
                  class="img-fluid"
                  alt="Dell Logo"
                />
              </div>
              <div class="col-6">
                <img
                  src="img/imgs/icons/traditional-desktop.svg"
                  class="img-fluid"
                  alt="Dell Logo"
                />
              </div>
              <div class="col-6">
                <img
                  src="img/imgs/icons/all-in-one.svg"
                  class="img-fluid"
                  alt="Dell Logo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Button trigger modal 
            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
              Launch demo modal
            </button>
   -->

    <!--
  <button v-if="!$auth.isAuthenticated" @click="login" class="button is-xl is-dark">Sign Up to Browse Events</button>
  <h3 v-if="$auth.isAuthenticated" class="is-size-3 has-background-dark welcome">Welcome, {{ $auth.user.name }}!</h3>
  --></div>
</template>
<script>

// EVENT BUS IMPORT
import { eventBus } from '../main';
import RecommendedList from '../components/RecommendedList.vue';
export default {
  name: "home",
  components: {
    RecommendedList
  },
  data() {
    return {
      selectedComputer: 0,
      brandisHidden: true,

    };
  },
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
//    editing data in event bus
      selectHardware() {
        this.selectedComputer++;
        eventBus.$emit('hardwareSelectionWasUpdated', this.selectedComputer);
      }
  },
};
</script>
<style lang="scss" scoped>
.hero {
  text-align: center;
  background-image: url("https://cdn.auth0.com/blog/vue-meetup/event-banner.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 400px;
}
.hero-body .title {
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6);
  padding: 40px 0 20px 0;
  font-size: 60px;
}
.subtitle {
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.7);
  font-size: 30px;
}
.button-block {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  position: absolute;
  bottom: -150px;
  .button {
    margin-right: 50px;
    padding-left: 50px;
    padding-right: 50px;
  }
  .welcome {
    width: 400px;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
  }
}
.is-xl {
  font-size: 1.7rem;
}
</style>